const SearchEngine3D = () => {
    return (
        <section className="w-full h-screen mb-24">
            <iframe
                src="https://allinone.prod.resimo.io/monday/kusocinskiego/#/?app=jeff&lang=pl"
                allow="fullscreen"
                className="allinone-iframe w-full h-full"
            ></iframe>
        </section>
    )
}

export default SearchEngine3D;